<template>
    <div>
        <r-e-dialog title="同住人" :visible.sync="dialogVisible" show-footer top="5vh" width="700px"
                    @click-submit="handleSubmit" @click-cancel="handleCancel" @close="handleCancel">
            <div style="text-align: right">
                <el-link type="primary" :underline="false" v-if="type==='save'" @click="save(null)">添加同住人</el-link>
            </div>
            <r-e-table ref="communityTableRef" v-loading="loading" :data="dataList" :columns="lesseeTongZuRenColumns"
                       :height="300" row-key-id="uuid" :showPagination="false">
                <el-table-column slot="toolbar" label="操作" v-if="type==='save'">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" @click="save(row)">修改</span>
                            <span class="table-btn" @click="delFamily(row)">删除</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </r-e-dialog>

        <dialog-save-tongzhuren ref="dialogSaveTongzhuren" :contractData="contractData" @getFamilyList="getFamilyList"/>
    </div>
</template>

<script>
import {lesseeTongZuRenColumns} from "@/views/rental-management/house-resource/data";
import {getFamilyList, delFamily} from "@/api/contract";
import {MessageError, MessageSuccess} from "@custom/message";

export default {
    name: "dialog-tongzhuren-list",
    data() {
        return {
            dialogVisible: false,
            lesseeTongZuRenColumns,
            dataList: [],
            type: "",
            contractData: {},
            loading: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    components: {
        dialogSaveTongzhuren: () => import("./dialog-save-tongzhuren")
    },
    methods: {
        //打开同住人弹框方法
        openDialog(data, type) {
            //保存合同信息
            this.contractData = data;
            //同住人不为0时，加载同住人列表
            if (data.familyCount !== 0) this.getFamilyList();
            // 保存操作类型，type=add，表示点击添加同住人打开的
            this.type = type;
            //打开同住人弹框
            this.dialogVisible = true;
        },

        //点击确认按钮事件
        handleSubmit() {
            //调用上层方法刷新合同信息
            this.$emit("getContractDetailData", this.contractData.uuid);
            //关闭弹窗
            this.dialogVisible = false;
        },

        //点击确认按钮事件
        handleCancel() {
            //调用上层方法刷新合同信息
            this.$emit("getContractDetailData", this.contractData.uuid);
            //关闭弹窗
            this.dialogVisible = false;
        },

        //加载同住人方法
        getFamilyList() {
            //获取合同uuid
            let {uuid} = this.contractData;
            //开启加载状态
            this.loading = true;
            //调用接口获取同住人列表
            getFamilyList({uuid}).then(res => {
                this.loading = false;
                //解构出同住人列表
                let {list} = res;
                //赋值
                this.dataList = list;
            }).catch(err => {});
        },

        //点击添加或修改事件
        save(data) {
            //调用添加同住人弹框组件方法打开弹窗
            this.$refs["dialogSaveTongzhuren"].openDialog(data);
        },

        //删除事件
        delFamily(data) {
            //提示是否删除
            this.$confirm('此操作将删除该同住人,是否继续?', '注意', {
                type: 'warning'
            }).then(async () => {
                //获取同住人uuid
                let {uuid} = data;
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading(loadingOptions);
                //调用接口删除同住人
                delFamily(uuid).then(res => {
                    MessageSuccess("删除同住人成功");
                    loading.close();
                    //调用加载同住人方法，刷新同住人列表
                    this.getFamilyList();
                }).catch(() => {
                    MessageError("删除同住人失败");
                    loading.close();
                });
            }).catch(() => {
            });
        }
    },
}
</script>

<style scoped>

</style>